import './App.css';

import axios from 'axios';

function App() {

  const handleRequest = async () => {
    const response = await axios.get('//api.cdn-example.grx-dev.com/lambda/cdn-example');
    console.log(response);
  };

  return (
    <div className="App">
      <button onClick={handleRequest}>Make Request</button>
    </div>
  );
}

export default App;
